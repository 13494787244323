<template>
  <div class="base-content">
    <query-frame
      :pageVisible="false"
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="状态">
          <a-select allowClear v-model="query.status">
            <a-select-option :value="0">
              启用
            </a-select-option>
            <a-select-option :value="1">
              禁用
            </a-select-option>
          </a-select>
        </query-item>
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import { feeList, feeStatus, deleteFeeById } from '@/api/fee';
import queryMixin from '@/mixin/queryMixin';

export default {
  name: 'feePrice',
  mixins: [queryMixin],
  props: {},
  data() {
    return {
      query: {
        status: null,
        feeName: null,
      },
      // 默认展示表格
      columns: [
        {
          title: '序号',
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          width: 300,
        },
        {
          title: '会费名称',
          dataIndex: 'feeName',
          key: 'feeName',
          width: 300,
        },
        {
          title: '价格',
          dataIndex: 'price',
          key: 'price',
          width: 120,
        },
        {
          title: '折扣率',
          dataIndex: 'discount',
          key: 'discount',
          width: 120,
        },
        {
          title: '时效类型',
          dataIndex: 'periodType',
          key: 'periodType',
          ellipsis: true,
          customRender: (text, row) => {
            switch (row.periodType) {
              case -1:
                return <section>年卡</section>;
                break;
              case -2:
                return <section>季卡</section>;
                break;
              case -3:
                return <section>月卡</section>;
                break;
              case 3:
                return <section>{row.days}天卡</section>;
                break;
              default:
                return <section>年卡</section>;
            }
          }
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 120,
          customRender: (text, row) => {
            return (
              <a-switch checked-children="启用" un-checked-children="禁用" checked={row.status === 0} onClick={ () => this.handleStatus(row)}/>
            );
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 200,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return (
              <section>
                <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleDelete(row) }>删除</a-button>
              </section>
            );
          }
        }
      ],
      dictOptions: {},
      dataList: []
    };
  },
  created() {
    this.handleQuery();
  },
  methods: {
    // 获取列表
    getData() {
      this.dataList = [];
      feeList(this.params).then((data) => {
        data.forEach((item) => {
          item.key = item.feeId;
        });
        this.dataList = data;
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'feePriceAdd'
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'feePriceEdit',
        params: {
          id: row.feeId
        }
      });
    },
    // 编辑
    handleDelete(row) {
      const self = this;
      this.$confirm({
        title: '删除次卡类型',
        content: '此操作将永远删除该车次卡类型，您确定要这么做吗？',
        okType: 'danger',
        onOk() {
          return new Promise((resolve, reject) => {
            deleteFeeById(row.feeId).then(() => {
              self.$message.success('删除成功');
              self.getData();
              resolve();
            }).catch(() => {
              reject();
            });
          }).catch((e) => console.log(e));
        },
        onCancel() {},
      });
    },
    // 启用或者禁用
    handleStatus(row) {
      const params = {
        feeId: row.feeId,
        status: row.status === 0 ? 1 : 0
      };
      feeStatus(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
